<template>
  <div>
    <div style="position: relative" v-if="getResult && !subscribed">
      <div class="d-flex flex-1 blured-div">
        <div class="wrapper">
          <div class="text-center">
            <h1 class="d-sm-none d-block">Unlock Discord VIP Group with an Upgrade</h1>
            <h1 style="font-size:3rem" class="d-none d-sm-block">Unlock Discord VIP Group with an Upgrade</h1>
            <h4>
              When you upgrade to Pro, you will be able to access keyword
              search, niches, source codes and more tools from AppLead.
            </h4>
          </div>
          <b-button
            style="font-size: 2rem"
            variant="outline-success"
            to="/pages/plan-selection"
          >
            Upgrade Now
          </b-button>
        </div>
      </div>
      <div style="filter: blur(3px)">
        <b-img
          :src="discordBannerPhone"
          class="w-100 d-md-none d-block"
          fluid
          alt="Responsive image"
        ></b-img>
          <b-img

          :src="discordBanner"
          class="w-100 d-none d-md-block"
          fluid
          alt="Responsive image"
        ></b-img>
      </div>
    </div>
    <div v-if="getResult && subscribed">
      <b-container>
        <b-row class="justify-content-center">
          <b-img
            :src="discordBannerPhone"
            class="w-100 d-md-none d-block cursor-pointer"
            fluid
            alt="Responsive image"
            @click="goToDiscord"
          ></b-img>
          <b-img
            :src="discordBanner"
            class="w-100 d-none d-md-block cursor-pointer"
            fluid
            alt="Responsive image"
            @click="goToDiscord"
          ></b-img>
        </b-row>
      </b-container>
    </div>
    <div v-if="!getResult">
      <LoaderVue />
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import LoaderVue from "@/components/Loader/Loader.vue";
import discordBanner from "@/assets/images/ds.svg";
import discordBannerPhone from "@/assets/images/ds-phone.svg";
import {
  BImg,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BContainer,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BImg,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BContainer,
    BRow,
    BCol,
    LoaderVue,
  },
  data() {
    return {
      getResult: null,
      subscribed: null,
      discordBanner,
      discordBannerPhone,
      discordLink: "https://discord.gg/k2Yv3xFu7n",
    };
  },
  mounted() {
    this.checkUserPlan();
  },
  watch: {},
  computed: {},
  methods: {
    checkUserPlan() {
      axios
        .post("/checkUserSubscription")
        .then(({ data }) => {
          this.getResult = true;
          this.subscribed = true;
        })
        .catch((err) => {
          if (err.status === 403) {
            this.getResult = true;
            this.subscribed = false;
          }
        });
    },
    goToDiscord() {
      window.open(this.discordLink, "_blank");
    },
  },
};
</script>

<style scoped>
.blured-div {
  position: absolute;
  background-color: black;
  opacity: 0.8;
  z-index: 300;
  padding-left: 5px;
  width: 100% !important;
  height: 100% !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
